import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SplitLayout from '../components/SplitLayout';
import loginIllustration from '../assets/illustration.svg';
import { useAuth } from '../context/AuthContext'; 
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { resendVerificationEmail } from '../services/authService';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const setGeneralMsg = useGeneralMsgUpdate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isInactiveUser, setIsInactiveUser] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);

  useEffect(() => {
    if (resendCooldown > 0) {
      const timer = setInterval(() => {
        setResendCooldown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendCooldown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e)  => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await login(formData.email, formData.password);
      navigate('/home');
    } catch (error) {
      const errorMsg = error? error : 'An unknown error occurred';
      setGeneralMsg(errorMsg, 'error');
      if (errorMsg.includes('not verified')) {
        setIsInactiveUser(true); // Show the inactive user prompt
        setFormData({ ...formData, password: '' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerification = async () => {
    if (resendCooldown > 0) return;
    setResendCooldown(60);
    try {
      await resendVerificationEmail(formData.email);
      setGeneralMsg('Verification email sent. Please check your inbox.', 'success');
    } catch (error) {
      setGeneralMsg('Failed to resend email. Try again later.', 'error');
    }
  };

  return (
    <SplitLayout illustration={loginIllustration}>
      <div className="login-form-container">
        <h1>Welcome Back!</h1>
        <p className='description'>Log in to access your LinkedIn notes and manage your connections.</p>
        <form onSubmit={handleSubmit} className='login-form'>
            <input
              type="email"
              name="email"
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div className="password-input-container">
              <input
                type={passwordVisible ? 'text' : 'password'} // Toggle between 'text' and 'password'
                name="password"
                placeholder='Password'
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setPasswordVisible(prevState => !prevState)} // Toggle password visibility
              >
                {!passwordVisible ? <FiEyeOff /> : <FiEye />} {/* Use icons instead of text */}
              </button>
            </div>
            {/* TODO: add forgot password */}
          <button className='login-button' type="submit" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'}
          </button>

          {isInactiveUser && (
            <div 
              className="inactive-user-backdrop" 
              onClick={() => setIsInactiveUser(false)} 
            >
              <div 
                className="inactive-user-msg" 
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the prompt
              >
                <button 
                  className="close-btn" 
                  onClick={() => setIsInactiveUser(false)}
                >
                  &times;
                </button>
                <p>Your email is not verified. Please check your inbox.</p>
                <button
                  className="resend-verification-btn"
                  onClick={handleResendVerification}
                  disabled={resendCooldown > 0}
                >
                  {resendCooldown > 0 ? `${resendCooldown}s` : 'Resend Verification Email'}
                </button>
              </div>
            </div>
          )}


          <p>Don't have an account? <Link to="/signup" className='color-blue'>Sign up</Link></p>
        </form>
      </div>
    </SplitLayout>
  );
};

export default Login;