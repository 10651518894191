import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Landing from './pages/Landing';
// import PreLaunch from './pages/PreLaunch';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Navbar from './components/Navbar';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';  
import AdminDashboard from './pages/AdminDashboard';

const App = () => {
  return (
    <div className="App">
    <Router>
      <Navbar />
      <Routes>
        {/* <Route 
          path="/pre-launch"
          element={<PublicRoute component={PreLaunch} />} 
        /> */}
        <Route 
          path="/privacy"
          element={<PublicRoute component={Privacy} />} 
        />
        <Route path="/" element={<Navigate to="/login" />} />
        {/* <Route 
          path="/"
          element={<PublicRoute component={Landing} />} 
        /> */}
        <Route 
          path="/login" 
          element={<PublicRoute component={Login} restricted />} 
        />
        <Route 
          path="/signup" 
          element={<PublicRoute component={Signup} restricted />} 
        />
        <Route 
          path="/landing" 
          element={<PublicRoute component={Landing} />} 
        />
        <Route 
          path="/home" 
          element={<PrivateRoute component={Home} />} 
        />
        <Route 
          path="/admin/analytics" 
          element={<AdminDashboard />} 
        />
        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} /> {/* This handles any unmatched paths */}
      </Routes>
      <Footer />
    </Router>
    </div>
  );
};

export default App;